.contact {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 2em;

   @media (max-width: 992px) {
      grid-template-columns: 1fr;
   }

   &__title {
      font-weight: 500;
      font-size: 1.625em;
      line-height: 130%;

      @media (max-width: 992px) {
         margin-bottom: 0;
      }
   }

   &__desc {
      font-weight: 500;
      font-size: 1.125em;
      line-height: 150%;
   }

   &:not(:last-child) {
      margin-bottom: 5em;
   }
}

.map {
   height: 360px;
   background-image: url('../../assets/images/map-desktop.png');
   background-repeat: no-repeat;
   background-position: center center;
   background-size: cover;
   margin-top: 5em;

   @media (max-width: 768px) {
      height: 600px;
      background-image: url('../../assets/images/map-mobile.png');
   }
}
