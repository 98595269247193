.skeleton {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 32px;

   &__wrapper {
      position: relative;
      background: $text-white;
      border-radius: 8px;
      padding: 48px 40px;
      overflow: hidden;
   }

   &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
   }

   &__solutions {
      width: 30%;
      height: 30px;
      border-radius: 8px;
      background-color: $bg-gray;
   }

   &__title {
      width: 100%;
      height: 30px;
      margin-bottom: 16px;
      border-radius: 8px;
      background-color: $bg-gray;
   }

   &__address {
      width: 60%;
      height: 24px;
      border-radius: 8px;
      background-color: $bg-gray;
   }

   &__img {
      width: 100%;
      height: 204px;
      border-radius: 8px;
      background-color: $bg-gray;
   }
}

.shimmer {
   width: 50%;
   height: 100%;
   background-color: rgba(255, 255, 255, 0.8);
   transform: skewX(-20deg);
   box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);

   &__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: loading 1.5s infinite;
   }
}

@keyframes loading {
   0% {
      transform: translateX(-150%);
   }
   50% {
      transform: translateX(-60%);
   }
   100% {
      transform: translateX(150%);
   }
}
