* {
   padding: 0;
   margin: 0;
   list-style: none;
   outline: none;
   box-sizing: border-box;
}

html {
   -ms-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
}

body {
   font-family: 'Montserrat', sans-serif;
   -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
   color: $text-black;
   font-size: 16px;

   @media (max-width: 992px) {
      font-size: 14px;
   }
}

button {
   font-family: 'Montserrat', sans-serif;
}

a,
span,
p,
b,
h1,
h2,
h3,
h4,
h5 {
   color: $text-black;
}

h1 {
   font-size: 4em;
}

h2 {
   font-weight: 600;
   font-size: 1.75em;
   line-height: 30px;
}

a {
   text-decoration: none;
}

img {
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -o-user-select: none;
   user-select: none;
}
