.button {
   display: inline-block;
   background-color: transparent;
   font-weight: 600;
   line-height: 100%;
   text-transform: uppercase;
   letter-spacing: 0.02em;
   text-align: center;
   cursor: pointer;
   border: 2px solid transparent;
   outline: none;
   @include noselect();

   &--fill {
      border-radius: 4px;
      background-color: $text-white;
      font-size: 1em;
      padding: 14px 22px;
      transition: background-color 0.2s ease, color 0.2s ease, border 0.2s ease;

      &:hover {
         background-color: transparent;
         color: $text-white;
         border: 2px solid $text-white;
         transition: background-color 0.2s ease, color 0.2s ease, border 0.2s ease;
      }
   }

   &--link {
      display: flex;
      align-items: center;
      transition: color 0.2s ease;

      img {
         margin-left: 6px;
         transition: transform 0.2s ease;
      }

      &:hover img {
         transform: rotate(45deg);
         transition: transform 0.2s ease;
      }
   }
}
