.objects-block {
   padding: 5em 0;

   &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5em;

      .title {
         font-weight: 500;
         font-size: 2.25em;
         line-height: 100%;
      }
   }

   &__wrapper {
      padding-bottom: 5em;
   }

   &__content {
      & > div:not(:last-of-type) {
         margin-bottom: 2em;
      }
   }
}

.object {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 2em;
   padding: 48px 40px;
   background-color: $text-white;
   border-radius: 8px;
   min-height: 300px;

   @media (max-width: 992px) {
      grid-template-columns: 1fr;
      padding: 24px 20px;
   }

   &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 992px) {
         height: 140px;
         order: 1;
      }

      .name {
         font-weight: 500;
         font-size: 1.5em;
         line-height: 130%;
         margin-bottom: 16px;
      }

      .address {
         font-weight: 500;
         font-size: 1em;
         line-height: 130%;
         color: $text-gray;
      }
   }

   &__photo {
      background-position: center center;
      background-size: cover;
      border-radius: 8px;
      @include noselect();

      @media (max-width: 992px) {
         height: 180px;
         order: 0;
      }
   }

   &__solutions {
      display: inline-flex;
      div {
         display: inline-flex;
         @include noselect();
         cursor: pointer;
      }

      div:not(:last-of-type) {
         margin-right: 12px;
      }
   }
}
