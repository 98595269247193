.header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-weight: 600;
   line-height: 100%;
   padding: 22px 0;

   &__nav {
      display: flex;
      align-items: center;
   }

   &__logo {
      margin-right: 60px;
   }

   &__links {
      display: flex;
      font-size: 0.875em;
      text-transform: uppercase;
      letter-spacing: 0.01em;

      @media (max-width: 992px) {
         display: none;
      }

      a {
         transition: color 0.2s ease;

         &:hover {
            color: $text-blue;
            transition: color 0.2s ease;
         }
      }

      a:not(:last-child) {
         margin-right: 42px;
      }
   }

   &__contact {
      display: flex;
      align-items: center;
      font-size: 1em;

      a {
         display: flex;
      }

      a:not(:last-child) {
         margin-right: 20px;
      }

      @media (max-width: 992px) {
         display: none;
      }
   }

   &__menu {
      display: none;
      @media (max-width: 992px) {
         display: flex;
      }
   }
}

.header__menu {
   cursor: pointer;
   padding: 8px;
}

.header__mobile {
   @media (min-width: 992px) {
      display: none;
   }
}

.header__nav--mobile {
   z-index: 999;
   border-radius: 8px;
   width: 60vw;
   padding: 32px;
   top: 76px;
   right: 16px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: fixed;
   background-color: $text-white;
   box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.15);

   a {
      font-size: 1em;
      text-transform: uppercase;
      letter-spacing: 0.01em;
      transition: color 0.2s ease;

      &:hover {
         color: $text-blue;
         transition: color 0.2s ease;
      }
   }

   a:not(:last-of-type) {
      margin-bottom: 20px;
   }

   &.disabled {
      display: none;
   }
}
