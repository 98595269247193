.footer {
   display: flex;
   flex-direction: column;
   font-weight: 500;
   line-height: 100%;
   padding: 80px 0;

   a {
      display: inline-flex;
      color: $text-white;
   }

   &__phone {
      font-size: 1.625em;
      margin-bottom: 16px;
   }

   &__mail {
      font-size: 1.25em;
      margin-bottom: 48px;
   }

   &__address {
      font-size: 1.125em;
      line-height: 130%;
   }
}
