.solution {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 32px;
   padding-top: 60px;
   padding-bottom: 80px;

   @media (max-width: 992px) {
      grid-template-columns: 1fr;
   }

   &__info {
      @media (max-width: 992px) {
         order: 1;
      }
   }

   &__title {
      font-weight: 500;
      font-size: 2.75em;
      line-height: 100%;
      margin-bottom: 48px;
   }

   &__desc {
      font-weight: 500;
      font-size: 1.25em;
      line-height: 150%;
   }

   &__img {
      background-size: 88%;
      background-repeat: no-repeat;
      background-position: 50% 50%;

      @media (max-width: 992px) {
         background-size: contain;
         order: 0;
         height: 300px;
      }
   }
}
