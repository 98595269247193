.carousel {
   width: 100%;
   color: $text-white;
   cursor: move;
   cursor: grab;
   cursor: -moz-grab;
   cursor: -webkit-grab;
   @include noselect();

   &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
   }

   &__wrapper {
      padding-bottom: 80px;
   }

   .slide {
      border-radius: 8px;
      overflow: hidden;
   }

   .content {
      height: 384px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 48px 40px;

      @media (max-width: 992px) {
         padding: 24px 20px;
         height: 500px;
      }

      .top,
      .bottom {
         display: flex;
         justify-content: space-between;
      }

      .top {
         .title {
            color: $text-white;
            width: 320px;
            font-weight: 500;
            font-size: 2em;
            line-height: 110%;
         }

         .number {
            font-size: 1em;
            font-weight: 600;
            letter-spacing: 0.02em;
            line-height: 100%;
            text-transform: uppercase;
         }
      }
   }
}

.navigation-wrapper {
   position: relative;
}

.arrow {
   width: 30px;
   height: 30px;
   position: absolute;
   cursor: pointer;
   bottom: 57px;
   right: 40px;
   @include noselect();

   &--left {
      right: 96px;
   }

   &--disabled {
      opacity: 0.5;
      pointer-events: none;
   }
}

.slide-mobile {
   border-radius: 8px;

   &__content {
      height: 420px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px 20px;
   }

   &__top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 600;
      font-size: 1em;
      line-height: 100%;
   }

   &__bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;

      .title {
         color: $text-white;
         width: 320px;
         font-weight: 500;
         font-size: 1.75em;
         line-height: 110%;
         text-align: center;
         margin-bottom: 1.25em;
      }

      a,
      button {
         width: 100%;
      }
   }
}
