.hero {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 2em;
   padding-top: 3.75em;
   padding-bottom: 5em;

   @media (max-width: 992px) {
      grid-template-columns: 1fr;
   }

   &__title {
      font-weight: 500;
      font-size: 3em;
      line-height: 100%;
   }

   &__desc {
      font-weight: 500;
      font-size: 1.25em;
      line-height: 130%;
   }
}
