.features-block {
   padding: 80px 0;

   &__title {
      font-weight: 500;
      font-size: 2.25em;
      line-height: 100%;
      margin-bottom: 80px;
   }

   &__content {
      .feature {
         display: grid;
         grid-template-columns: 1fr 1fr;
         grid-gap: 32px;

         @media (max-width: 992px) {
            grid-template-columns: 1fr;
         }

         &__title {
            font-weight: 500;
            font-size: 1.625em;
            line-height: 130%;

            @media (max-width: 992px) {
               margin-bottom: 0;
            }
         }

         &__desc {
            font-weight: 500;
            font-size: 1.125em;
            line-height: 150%;
         }
      }

      .feature:not(:last-of-type) {
         margin-bottom: 80px;
      }
   }
}
