@import 'fonts';
@import 'variables';
@import 'mixins';

@import 'components/all';

@import 'libs/normalize';

.container {
   max-width: $container-width;
   margin: 0 auto;

   @media (max-width: 1200px) {
      padding: 0 32px;
   }

   @media (max-width: 992px) {
      padding: 0 16px;
   }
}

.section {
   &--gray {
      background-color: $bg-gray;
   }

   &--black {
      background-color: $text-black;
   }
}

.page {
   &__header {
      max-width: 544px;
      padding-top: 3.75em;
      padding-bottom: 5em;
   }

   &__title {
      font-weight: 500;
      font-size: 3em;
      line-height: 100%;
      margin-bottom: 2em;
   }

   &__desc {
      font-weight: 500;
      font-size: 1.25em;
      line-height: 150%;
   }
}

// about page

.numbers {
   display: grid;
   grid-template-columns: 1fr 1fr;
   margin-top: 3em;

   &__item {
      div:first-child {
         font-size: 5em;
         line-height: 100%;
         margin-bottom: 12px;
      }
      div:last-child {
         font-weight: 500;
         font-size: 1.25em;
         line-height: 120%;
      }
   }
}

// custom react-tooltip

.__react_component_tooltip {
   font-size: 15px !important;
   background: $text-black !important;

   span {
      color: $text-white !important;
   }

   &::after {
      border-top-color: $text-black !important;
   }

   &.place-right::after {
      border-right-color: $text-black !important;
   }

   &.place-right::before {
      display: none;
   }
}
