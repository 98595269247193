$container-width: 1120px;

// colors

$text-black: #000000;
$text-white: #ffffff;
$text-gray: #838383;
$text-blue: #1976d2;

$bg-gray: #f9fafc;
